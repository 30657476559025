@media screen and (min-width: 480px) {

.background {
    background-image: url("../../../public/Dome\ Large.png");
    background-size: auto;
    background-repeat: no-repeat;
    height: 100vh
}

.background h1{
    color: white;
    font-size: 48px;
    padding: 10px 40px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.appTitleContainer {
    font-size: 48px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    width: 100%;
    height: 100px;
    align-items: center;
}

.appTitle {
    font-size: 48px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    width: 100%;
    text-align: center;
    justify-content: center;
}

.renderingTitle {
    font-size: 48px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    width: 100%;
    height: 50px;
    text-align: center;
}

.appSection {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.appInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    
}  

.appText {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.appText h3{
    width: 50%;
    text-align: center;
    padding-bottom: 50px;
}

.appText h5{
    width: 50%;
    text-align: center;
    padding-top: 50px;
}

.button {
    background-color: Black;
    text-decoration: none;
    color: white;
    border-radius: 10px;
    font-weight: bold;
}

.buttonLink {
    padding: 20px;
    background-color: Black;
    text-decoration: none;
    color: white;
    border-radius: 10px;
    font-weight: bold;
}

.button:hover {
    background-color: white;
    text-decoration: none;
    color: black;
    border-radius: 10px;
    font-weight: bold;
}

.buttonLink:hover {
    padding: 20px;
    background-color: white;
    text-decoration: none;
    color: black;
    border-radius: 10px;
    font-weight: bold;
}

.appPhoto {
    width: 25%;
}

.appPhotoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
}

}

@media screen and (max-width: 480px) {

    .background {
        background-image: url("../../../public/Dome\ Small.png");
        background-size:cover;
        background-repeat: no-repeat;
        height: 400px
    }

    .background h1{
        color: Black;
        font-size: 30px;
        padding: 5px 40px;
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    }

    .appTitleContainer {
        font-size: 20px;
        text-align: center;
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        width: 100%;
        height: 50px;
        align-items: center;
    }

    .appTitle {
        font-size: 26px;
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    .renderingTitle {
        font-size: 26px;
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        width: 100%;
        height: 25px;
        text-align: center;
    }
    
    .appSection {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }
    
    .appInfo {
        display: block;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        
    }  
    
    .appText {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    
    .appText h3{
        width: 100%;
        text-align: center;
        padding-bottom: 50px;
    }
    
    .appText h5{
        width: 100%;
        text-align: center;
        padding-top: 50px;
    }
    
    .button {
        background-color: Black;
        text-decoration: none;
        color: white;
        border-radius: 10px;
        font-weight: bold;
    }
    
    .buttonLink {
        padding: 20px;
        background-color: Black;
        text-decoration: none;
        color: white;
        border-radius: 10px;
        font-weight: bold;
    }
    
    .button:hover {
        background-color: white;
        text-decoration: none;
        color: black;
        border-radius: 10px;
        font-weight: bold;
    }
    
    .buttonLink:hover {
        padding: 20px;
        background-color: white;
        text-decoration: none;
        color: black;
        border-radius: 10px;
        font-weight: bold;
    }
    
    .appPhoto {
        width: 50%;
    }
    
    .appPhotoContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px 0;
    }

    

}